import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v(_vm._s(_vm.$t("informalCareWidget")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-heart")])],1),_c(VCardText,[(_vm.loading)?_c('loader'):(_vm.care_requests.length !== 0)?[_c(VDataTable,{staticClass:"adjusted-margin",attrs:{"headers":_vm.headers,"items":_vm.care_requests,"items-per-page":4,"sort-desc":"","sort-by":"created_at","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onRowClick(item)}}},[_c('td',[_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD-MM-YYYY"))+" ")])]),_c('td',[_c('small',[_vm._v(" "+_vm._s(_vm.$t(item.approval_status))+" ")])]),_c('td',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onRowClick(item)}}},[_c(VIcon,[_vm._v("mdi-eye")])],1)],1)])]}}])})]:_c('div',{staticClass:"middle-aligned-button"},[_c(VBtn,{attrs:{"color":"primary","rounded":"","large":""},on:{"click":function($event){return _vm.onRowClick(_vm.item)}}},[_vm._v(_vm._s(_vm.$t("requestInformalCare")))])],1)],2),_c(VFooter,{class:_vm.loading ? 'pa-5' : '',staticStyle:{"min-height":"42px"},attrs:{"dark":"","color":"primary"}},[_c(VSpacer),(!_vm.loading && !_vm.pending_request)?_c(VBtn,{attrs:{"text":"","small":""},on:{"click":_vm.newRequest}},[_vm._v(" "+_vm._s(_vm.$t("requestInformalCare"))+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e(),(!_vm.loading)?_c('informal-care-request-modal',{ref:"modal",attrs:{"has_open_request":_vm.checkOpenRequest},on:{"refresh":_vm.getCareRequests,"requestPending":function($event){_vm.pending_request = true}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }