<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown" v-model="show" max-width="800" scrollable>
        <v-card :color="local_status == 'SENT' ? 'primary' : null" :dark="local_status == 'SENT'">
            <v-toolbar flat color="primary" dark>{{ $t("requestInformalCareSupport") }}
                <v-spacer></v-spacer>
                <v-btn :loading="loading" v-if="request_submitted" small outlined dark class="mr-3"
                    @click="submitRequest('DRAFT')">
                    <span v-if="$vuetify.breakpoint.mdAndUp"> {{ $t("submitAsDraft") }}</span>
                    <v-icon small :right="$vuetify.breakpoint.mdAndUp">mdi-content-save</v-icon>
                </v-btn>
                <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>

                <div v-if="request_submitted" class="success-message">
                    <span>
                        <v-icon>mdi-check-circle-outline</v-icon><br />
                        {{ $t("requestSubmitted") }}
                    </span>
                </div>

                <div v-else>
                    <v-stepper class="mt-5" flat v-model="step" vertical>
                        <v-stepper-step :rules="[() => carereceiver_form]" editable :complete="step > 1" step="1"
                            v-if="$config.show_carereceiver_address">
                            <h3>{{ $t("carereceiverRecords") }}</h3>
                        </v-stepper-step>

                        <v-stepper-content step="1" v-if="$config.show_carereceiver_address">
                            <v-card>
                                <v-card-text>
                                    <p>{{ $t("carereceiverIntro") }}</p>
                                    <v-form ref="carereceiver_form">
                                        <v-text-field v-model="request.carereceiver.prefix"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            :label="$t('prefix')"></v-text-field>
                                        <v-text-field class="required" v-model="request.carereceiver.first_name"
                                            :disabled="request.approval_status == 'APPROVED'" :label="$t('firstName')"
                                            :rules="[required]" validate-on-blur></v-text-field>
                                        <v-text-field v-model="request.carereceiver.preposition"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            :label="$t('preposition')"></v-text-field>
                                        <v-text-field class="required" v-model="request.carereceiver.last_name"
                                            :disabled="request.approval_status == 'APPROVED'" :label="$t('lastName')"
                                            :rules="[required]" validate-on-blur></v-text-field>

                                        <date-picker v-if="$config.show_carereceiver_address"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            v-model="request.carereceiver.date_of_birth" :label="$t('dateOfBirth')" />

                                        <v-text-field v-if="$config.show_carereceiver_address" class="hide-arrows"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            v-model="request.carereceiver.phonenumber" :label="$t('phoneNumber')"
                                            :rules="phoneNumberFormat" v-mask="'##########'" validate-on-blur
                                            type="number"></v-text-field>
                                        <v-text-field v-if="$config.show_carereceiver_address"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            v-model="request.carereceiver.email" :label="$t('email')"
                                            :rules="emailFormat" validate-on-blur></v-text-field>
                                        <address-lookup v-if="$config.show_carereceiver_address"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            :address="request.carereceiver"></address-lookup>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-step :rules="[() => caregiver_form]" editable
                            :complete="step > ($config.show_carereceiver_address ? 2 : 1)"
                            :step="$config.show_carereceiver_address ? 2 : 1">
                            <h3>{{ $t("caregiverRecords") }}</h3>
                        </v-stepper-step>

                        <v-stepper-content :step="$config.show_carereceiver_address ? 2 : 1">
                            <v-card>
                                <v-card-text>
                                    <p>{{ $t("caregiverIntro") }}</p>
                                    <v-form ref="caregiver_form">
                                        <v-text-field v-model="request.caregiver.prefix"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            :label="$t('prefix')"></v-text-field>
                                        <v-text-field class="required" v-model="request.caregiver.first_name"
                                            :disabled="request.approval_status == 'APPROVED'" :label="$t('firstName')"
                                            :rules="[required]" validate-on-blur></v-text-field>
                                        <v-text-field v-model="request.caregiver.preposition"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            :label="$t('preposition')"></v-text-field>
                                        <v-text-field class="required" v-model="request.caregiver.last_name"
                                            :disabled="request.approval_status == 'APPROVED'" :label="$t('lastName')"
                                            :rules="[required]" validate-on-blur></v-text-field>

                                        <date-picker v-model="request.caregiver.date_of_birth"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            :label="$t('dateOfBirth')" />

                                        <v-text-field class="hide-arrows" v-model="request.caregiver.phonenumber"
                                            :disabled="request.approval_status == 'APPROVED'" :label="$t('phoneNumber')"
                                            :rules="phoneNumberFormat" validate-on-blur
                                            v-mask="'##########'"></v-text-field>
                                        <v-text-field readonly disabled class="required"
                                            v-model="request.caregiver.email" :label="$t('email')" :rules="emailRules"
                                            validate-on-blur></v-text-field>

                                        <address-lookup :address="request.caregiver"
                                            :disabled="request.approval_status == 'APPROVED'"></address-lookup>

                                        <v-checkbox v-model="request.extension"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            :label="$t('careRequestExtension')"></v-checkbox>
                                    </v-form>

                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-step :rules="[() => questions_form]" editable
                            :complete="step > ($config.show_carereceiver_address ? 3 : 2)"
                            :step="$config.show_carereceiver_address ? 3 : 2">
                            <h3>{{ $t("informalCareSupport") }}</h3>
                        </v-stepper-step>

                            <v-stepper-content :step="$config.show_carereceiver_address ? 3 : 2">
                                <v-card>
                                    <v-card-text>
                                        <p>{{ $t("informalCareIntro") }}</p>
                                        <p class="explainer">Let op: vermeld hier geen details van het ziektebeeld van de zorgvrager.</p>
                                        <v-form ref="questions_form">
                                            <v-text-field
                                                class="required"
                                                v-model="request.questions.hours_per_week"
                                                :label="$t('hoursCarePerWeek')"
                                                type="number"
                                                min="0"
                                                :rules="[required]"
                                                validate-on-blur
                                                v-mask="'###'"
                                            ></v-text-field>
                                            <date-picker v-model="request.since_date" :label="$t('careSinceDate')" />
                                            <v-textarea
                                                class="required"
                                                v-model="request.questions.relationship"
                                                :label="$t('relationshipToCarereceiver')"
                                                :rules="[required]"
                                                validate-on-blur
                                                :hint="$t('relationshipToCarereceiverHint')"
                                            ></v-textarea>
                                            <v-textarea
                                                class="required"
                                                v-model="request.questions.caregiver_duties"
                                                :label="$t('caregiverDuties')"
                                                :rules="[required]"
                                                validate-on-blur
                                                :hint="$t('caregiverDutiesHint')"
                                            ></v-textarea>
                                            <!-- <v-textarea
                                                class="required"
                                                v-model="request.questions.carereceiver_clinical_picture"
                                                :label="$t('careReceiverClinicalPicture')"
                                                :rules="[required]"
                                                validate-on-blur
                                                :hint="$t('careReceiverClinicalPictureHint')"
                                            ></v-textarea> -->
                                        </v-form>

                                        <v-btn
                                            rounded
                                            block
                                            class="mt-5"
                                            color="primary"
                                            @click="validateStep(4, 'questions_form')"
                                        >
                                            {{ $t("next") }}
                                            <v-icon right>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-stepper-content>

                            <v-stepper-step
                                :rules="[() => terms_form]"
                                editable
                                :step="$config.show_carereceiver_address ? 4 : 3"
                            >
                                <h3>{{ $t("declarationAndConsent") }}</h3>
                            </v-stepper-step>
                            <v-stepper-content :step="$config.show_carereceiver_address ? 4 : 3">
                                <v-card>
                                    <v-card-text>
                                        <p>{{ $t("caregiverRequestText") }}</p>
                                        <v-form ref="terms_form">
                                            <v-text-field
                                                class="required"
                                                v-model="request.terms.place"
                                                :label="$t('signedAt')"
                                                :rules="[required]"
                                                validate-on-blur
                                            ></v-text-field>
                                            <date-picker
                                                v-model="request.terms.date"
                                                :label="$t('signedOn')"
                                                :rules="[required]"
                                                validate-on-blur
                                            />

                                        <v-checkbox v-model="request.filled_in_by" :label="$t('filledInByThirdLabel')"
                                            :disabled="request.approval_status == 'APPROVED'">
                                        </v-checkbox>

                                        <v-row v-if="request.filled_in_by">
                                            <v-col>
                                                {{ $t("filledInByThirdText") }}
                                                <v-text-field class="required" v-model="request.filled_in_by_name"
                                                    :disabled="request.approval_status == 'APPROVED'"
                                                    :label="$t('name')" :rules="[required]"
                                                    validate-on-blur></v-text-field>
                                                <v-text-field class="hide-arrows"
                                                    :disabled="request.approval_status == 'APPROVED'"
                                                    v-model="request.filled_in_by_phone_number"
                                                    :label="$t('phoneNumber')" :rules="phoneNumberFormat"
                                                    v-mask="'##########'" validate-on-blur type="number"></v-text-field>

                                                <v-text-field class="required" v-model="request.filled_in_by_email"
                                                    :disabled="request.approval_status == 'APPROVED'"
                                                    :label="$t('email')" :rules="emailRules"
                                                    validate-on-blur></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <div v-if="$config.privacy_policy_url">
                                            <v-checkbox v-model="request.terms.agreed_privacy_policy" class="required"
                                                :disabled="request.approval_status == 'APPROVED'" :rules="[required]">
                                                <template v-slot:label>
                                                    <div>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <a target="_blank" :href="$config.privacy_policy_url"
                                                                    @click.stop v-on="on">
                                                                    {{ $t("agreeWithPrivacyPolicy") }}
                                                                </a>
                                                            </template>
                                                            {{ $t("urlNewWindow") }}
                                                        </v-tooltip>
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                        </div>

                                        <v-checkbox v-if="hasTranslation('subscribeToCareNewsletter')"
                                            :disabled="request.approval_status == 'APPROVED'"
                                            v-model="request.terms.newsletter" :label="$t('subscribeToCareNewsletter')">
                                        </v-checkbox>
                                    </v-form>
                                    <v-btn :loading="loading" v-if="request.approval_status == 'OPEN'" rounded
                                        class="mt-5" x-large block color="primary" @click="submitRequest('FINISHED')">
                                        {{ $t("submitRequest") }}
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AddressLookup from "./AddressLookup.vue";
export default {
    components: { AddressLookup },
    props: ["has_open_request"],
    data() {
        return {
            loading: false,
            show: false,
            step: 1,
            error: false,
            required: (value) => !!value || this.$t("mandatory"),
            emailRules: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("validEmail"),
            ],
            emailFormat: [(v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("validEmail")],
            postcalCode: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^(?:NL-)?(\d{4})\s*([A-Za-z]{2})$/.test(v) || this.$t("invalidPostalCode"),
            ],
            phoneNumberRules: [(v) => v.length == 10 || this.$t("incorrectPhonenumber")],
            phoneNumberFormat: [(v) => !v || v.length == 10 || this.$t("incorrectPhonenumber")],

            request: {
                carereceiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                },
                caregiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                },
                questions: {
                    relationship: "",
                    caregiver_duties: "",
                    carereceiver_clinical_picture: "",
                    hours_per_week: "",
                },
                terms: {
                    place: "",
                    date: "",
                    agreed_privacy_policy: false,
                    agreed_conditions: true,
                },
                status: "DRAFT",
                accesstoken: this.accesstoken,
                since_date: "",
                approval_status: "OPEN",
            },
            request_submitted: false,
            saved_as_draft: false,
            error_on_save: false,
            loading_address: false,
            carereceiver_form: true,
            caregiver_form: true,
            questions_form: true,
            terms_form: true,
            filled_in_by: false,
            filled_in_by_email: "",
            filled_in_by_name: "",
            filled_in_by_phone_number: "",
            extension: false,
            user: {
                first_name: "",
                preposition: "",
                last_name: "",
                email: "",
                postal_code: "",
                housenumber: "",
                phonenumber: "",
            },
            local_status: "DRAFT",
        };
    },
    created() {
        this.getUser();
    },
    methods: {
        setRequest(request) {
            this.request.questions.hours_per_week = request.hours_per_week;
            this.request.since_date = request.since_date;
            this.request.questions.relationship = request.relationship;
            this.request.questions.caregiver_duties = request.caregiver_duties;
            this.request.questions.carereceiver_clinical_picture = request.carereceiver_clinical_picture;
            this.request.terms.place = request.terms_location;
            this.request.terms.date = request.terms_date;
            this.request.terms.agreed_privacy_policy = request.agreed_privacy_policy;
            this.request.terms.agreed_conditions = request.agreed_conditions;
            this.request.carereceiver.prefix = request.carereceiver.prefix;
            this.request.carereceiver.first_name = request.carereceiver.first_name;
            this.request.carereceiver.preposition = request.carereceiver.preposition;
            this.request.carereceiver.last_name = request.carereceiver.last_name;
            this.request.carereceiver.date_of_birth = request.carereceiver.date_of_birth;
            this.request.carereceiver.phonenumber = request.carereceiver.phonenumber;
            this.request.carereceiver.email = request.carereceiver.email;
            this.request.carereceiver.postal_code = request.carereceiver.postal_code;
            this.request.carereceiver.housenumber = request.carereceiver.housenumber;
            this.request.carereceiver.housenumber_addition = request.carereceiver.housenumber_addition;
            this.request.carereceiver.street = request.carereceiver.street;
            this.request.carereceiver.city = request.carereceiver.city;
            this.request.caregiver.prefix = request.caregiver.prefix;
            this.request.caregiver.first_name = request.caregiver.first_name;
            this.request.caregiver.preposition = request.caregiver.preposition;
            this.request.caregiver.last_name = request.caregiver.last_name;
            this.request.caregiver.date_of_birth = request.caregiver.date_of_birth;
            this.request.caregiver.phonenumber = request.caregiver.phonenumber;
            this.request.caregiver.email = request.caregiver.email;
            this.request.caregiver.postal_code = request.caregiver.postal_code;
            this.request.caregiver.housenumber = request.caregiver.housenumber;
            this.request.caregiver.housenumber_addition = request.caregiver.housenumber_addition;
            this.request.caregiver.street = request.caregiver.street;
            this.request.caregiver.city = request.caregiver.city;
            this.request.extension = request.extension;
            this.request.filled_in_by = request.filled_in_by;
            this.request.filled_in_by_email = request.filled_in_by_email;
            this.request.filled_in_by_name = request.filled_in_by_name;
            this.request.filled_in_by_phone_number = request.filled_in_by_phone_number;
            this.request.terms.agreed_privacy_policy = request.agreed_privacy_policy;
            this.request.terms.newsletter = request.newsletter;
            this.request.status = request.status;
            this.request.accesstoken = request.accesstoken;
            this.request.approval_status = request.approval_status;
            this.request.approval_date = request.approval_date;
            this.request.approval_remark = request.approval_remark;
            this.request.approved_by_user_id = request.approved_by_user_id;
            this.request.automatically_approved = request.automatically_approved;

            this.show = true;
        },
        getUser() {
            this.$http
                .get(this.$config.backend_url + "/user/participant")
                .then((response) => {
                    this.request.caregiver.first_name = response.data.firstName || "";
                    this.request.caregiver.preposition = response.data.preposition || "";
                    this.request.caregiver.last_name = response.data.lastName || "";
                    this.request.caregiver.email = response.data.email || "";
                    this.request.caregiver.postal_code = response.data.postalCode || "";
                    this.request.caregiver.housenumber = response.data.houseNumber || "";
                    this.request.caregiver.phonenumber = response.data.phoneNumber || "";

                    this.user.first_name = response.data.firstName || "";
                    this.user.preposition = response.data.preposition || "";
                    this.user.last_name = response.data.lastName || "";
                    this.user.email = response.data.email || "";
                    this.user.postal_code = response.data.postalCode || "";
                    this.user.housenumber = response.data.houseNumber || "";
                    this.user.phonenumber = response.data.phoneNumber || "";
                })
                .catch((error) => {
                    this.error = true;
                    this.error_response = error;
                });
        },
        validateStep(targetStep, form) {
            this[form] = this.$refs[form].validate();
            if (this[form]) {
                if (this.$config.show_carereceiver_address) {
                    this.step = targetStep;
                } else {
                    // Aanpassen van de stapnummers als de eerste stap wordt overgeslagen
                    this.step = targetStep === 1 ? 2 : targetStep === 2 ? 3 : 4;
                }
            }
        },
        getPreviousRequest() {
            this.$http
                .get(this.$config.backend_url + "/informalcare-request")
                .then((response) => {
                    if (response.data.informalcare_request) {
                        this.request = response.data.informalcare_request;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.error_on_save = true;
                });
        },
        validateForms() {
            let isCarereceiverFormValid = true;

            if (this.$config.show_carereceiver_address && this.$refs.carereceiver_form) {
                isCarereceiverFormValid = this.$refs.carereceiver_form.validate();
            }

            this.caregiver_form = this.$refs.caregiver_form.validate();
            this.questions_form = this.$refs.questions_form.validate();
            this.terms_form = this.$refs.terms_form.validate();

            if (isCarereceiverFormValid && this.caregiver_form && this.questions_form && this.terms_form) {
                return true;
            }

            return false;
        },

        submitRequest(status) {
            if (status == "FINISHED" && !this.validateForms()) {
                return this.$toast.error(this.$t("notAllFieldsWereFilled"));
            }
            this.error = false;
            this.request.status = status;
            this.request.accesstoken = this.accesstoken;
            this.loading = true;

            this.$http
                .post(this.$config.backend_url + "/informalcare-requests/create", this.request, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then(() => {
                    this.loading = false;
                    if (this.request.status == "DRAFT") {
                        this.$toast.success(this.$t("savedAsDraft"));
                    }
                    if (this.request.status == "FINISHED") {
                        this.request_submitted = true;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.error_on_save = true;
                    this.$toast.error(this.$t("couldNotSave"));
                });
        },
        hasTranslation(key) {
            return this.$t(key) !== key;
        },
        fixStepNavigation() {
            // Controleer of de huidige stap nog bestaat
            if (!this.$refs[`step-${this.step}`]) {
                this.step = 1; // Reset naar stap 1 als de huidige stap niet geldig is
            }
        }
    },
    computed: {
        accesstoken() {
            return this.$store.getters.selectedCard ? this.$store.getters.selectedCard.token : null;
        },
    },
    watch: {
        show(val) {
            if (!val) {
                this.request_submitted = false;
                this.$emit("refresh");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.success-message {
    min-height: 500px;
    line-height: 500px;
    text-align: center;

    span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        font-size: 30px;
        margin-left: 50px;
        margin-right: 50px;
        font-weight: 100;

        i {
            font-size: 150px;
            margin-bottom: 30px;
        }
    }
}
</style>
