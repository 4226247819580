<template>
    <v-app>
        <div class="white-header"></div>
        <Header />
        <v-main class="mb-10">
            <v-container class="mijnpas-subheader">
                <v-row>
                    <v-col class="mb-2">
                        <router-link v-if="!$vuetify.breakpoint.mdAndDown" to="dashboard" class="d-flex justify-left text-decoration-none mr-2 toolbar3-min-width">
                            <h1 class="primary--text">{{ $t("myPass") }}</h1>
                            <v-img class="small-card-image" :src="$config.card_url" contain max-height="25" max-width="60" />
                        </router-link>
                        <v-breadcrumbs class="ma-0 pa-0 mb-3" :items="items">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
                                    <v-icon left>{{ item.icon }}</v-icon>
                                    {{ $t(item.text).toUpperCase() }}
                                </v-breadcrumbs-item>
                            </template>
                            <template v-slot:divider>
                                <v-icon>mdi-arrow-right</v-icon>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" class="text-right">
                        <div class="d-none d-md-block d-lg-block">
                            <v-btn v-for="(item, index) in menu" :key="index" :to="item.link" text small color="primary" class="ml-1" rounded>
                                <v-icon left v-text="item.icon"></v-icon>
                                {{ $t(item.title) }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="!$vuetify.breakpoint.mdAndDown">
                    <v-col class="pt-0 pb-5">
                        <span class="text-h4" style="margin-left: -2px">
                            {{ $t($route.meta.title) }}
                        </span>
                    </v-col>
                </v-row>
                <v-row class="mt-0 mb-5">
                    <v-col cols="12" lg="4" class="mt-lg-2">
                        <small class="card-title">{{ $t("passInfo") }}</small>
                        <v-select :prepend-inner-icon="'mdi-credit-card'" attach dense solo :items="$store.getters.cards" persistent-placeholder hide-details v-model="selected_card" :class="$vuetify.breakpoint.mdAndDown ? 'mb-3' : ''" return-object item-text="token" item-value="token">
                        </v-select>
                    </v-col>
                    <v-col class="mt-lg-2">
                        <span class="font-weight-light mr-2 text-bottom balance-label">{{
                            $t("capitalBalance")
                            }}</span>
                        <span v-if="$store.getters.selectedCard" class="font-weight-bold text-h4">{{ $store.getters.selectedCard.balance | currency }}</span>
                        <span v-else class="font-weight-bold text-h4">{{
                            0 | currency
                            }}</span>
                        <v-btn v-if="mollieActive" absolute color="primary" class="ml-5" style="margin-top: 6px" @click="$store.dispatch('setShowDepositModal', true)" rounded small>
                            <v-icon small :left="$vuetify.breakpoint.mdAndUp">mdi-plus</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp">{{
                                $t("addBalance")
                                }}</span></v-btn>
                    </v-col>
                    <v-col class="mt-lg-2 text-right">
                        <v-btn style="margin-top: 5px" color="primary" @click="$store.dispatch('setShowQrCodeModal', true)" rounded small>
                            <v-icon small :left="$vuetify.breakpoint.mdAndUp">mdi-barcode-scan</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{ $t("showBarcode") }}
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
                <router-view class="mt-lg-10" />
            </v-container>
        </v-main>

        <foot v-if="$vuetify.breakpoint.mdAndUp"></foot>
        <deposit-modal></deposit-modal>
        <qr-code-modal></qr-code-modal>
    </v-app>
</template>

<script>
import Header from "./Header";
import Foot from "./Footer.vue";
import menu from "../../menu.js";
import DepositModal from "../components/DepositModal.vue";
import QrCodeModal from "../components/QrCodeModal.vue";

export default {
    name: "Home",
    components: {
        Header,
        Foot,
        DepositModal,
        QrCodeModal,
    },
    data() {
        return {
            menu,
            selected_card: null,
            card_ids: [],
            cards: [],
        };
    },
    mounted() {
        this.getUser();
        this.getCardIds();
    },
    methods: {
        getUser() {
            this.$http
                .get(this.$config.backend_url + "/user")
                .then((response) => {
                    this.$store.dispatch("setUser", response.data);
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = true;
                    this.error_response = error;
                });
        },

        getCardIds() {
            this.$http
                .get(this.$config.backend_url + "/cards")
                .then((response) => {
                    for (var i = 0; i < response.data.length; i++) {
                        this.card_ids.push(response.data[i].token);
                    }

                    this.cards = [];
                    this.card_ids.forEach((card_id) => {
                        this.setCard(card_id);
                    });
                    this.selectedCard();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async setCard(id) {
            this.$http
                .get(this.$config.backend_url + "/cards/" + id)
                .then((response) => {
                    let card = {
                        token: id,
                        wallets: response.data.wallets,
                        balance: response.data.amount,
                    };
                    this.cards.push(card);
                    this.selectedCard();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        selectedCard() {
            if (
                !this.$store.getters.selectedCard &&
                this.$store.getters.cards.length > 0
            ) {
                this.$store.dispatch("setSelectedCard", this.$store.getters.cards[0]);
            }
            this.selected_card = this.$store.getters.selectedCard;
        },
    },
    computed: {
        getToken() {
            return this.selected_card ? this.selected_card.token : null;
        },
        mollieActive() {
            if (this.$config.allow_deposit == true) {
                return true;
            }
            return false;
        },
        items() {
            let items = [
                {
                    disabled: true,
                    exact: true,
                    link: true,
                    text: "dashboard",
                    divider: "mdi-arrow-right",
                    icon: "mdi-view-dashboard",
                },
            ];

            if (this.$route.name != "dashboard") {
                items = [
                    {
                        disabled: false,
                        exact: false,
                        link: true,
                        text: "dashboard",
                        to: "dashboard",
                        divider: "mdi-arrow-right",
                        icon: "mdi-view-dashboard",
                    },
                    {
                        disabled: false,
                        exact: false,
                        link: false,
                        text: this.$route.meta.title,
                        to: this.$route.name,
                        icon: this.$route.meta.icon,
                    },
                ];
            }

            return items;
        },
    },
    watch: {
        selected_card: {
            handler() {
                this.$store.dispatch("setSelectedCard", this.selected_card);
            },
        },
        cards: {
            handler() {
                this.$store.dispatch("setCards", this.cards);
            },
        },
    },
};
</script>

<style lang="scss">
.card {
    width: 100%;
    border-radius: 30px;
    z-index: 50;
    position: relative;
    filter: drop-shadow(0 2px 4px #7f7f7f);
}

.card-panel {
    width: 100%;
    margin-top: -55px;
}

.background {
    background-color: #f5f5f5;
}

.theme--dark,
a {
    color: white !important;
}

.white-mode,
a {
    color: unset !important;
}
</style>
