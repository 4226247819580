<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{
        $t("informalCareWidget")
      }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-heart</v-icon>
    </v-card-title>

    <v-card-text>
      <loader v-if="loading"></loader>
      <template v-else-if="care_requests.length !== 0">
        <v-data-table :headers="headers" :items="care_requests" :items-per-page="4" sort-desc sort-by="created_at"
          hide-default-footer dense class="adjusted-margin">

          <template v-slot:item="{ item }">
          <tr @click="onRowClick(item)" style="cursor: pointer;">
            <td>
              <small> {{ item.created_at | moment("DD-MM-YYYY") }} </small>
            </td>
            <td>
              <small> {{ $t(item.approval_status) }} </small>
            </td>
            <!-- td with button -->
            <td>
              <v-btn icon @click.stop="onRowClick(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
          </template>
        </v-data-table>

      </template>
      <!-- else a centralized button where they can request mantelzorg -->
       <div v-else class="middle-aligned-button">
          <v-btn @click="onRowClick(item)" color="primary" rounded large>{{ $t("requestInformalCare") }}</v-btn>
       </div>

    </v-card-text>

    <v-footer dark color="primary" :class="loading ? 'pa-5' : ''" style="min-height: 42px;">
      <v-spacer></v-spacer>
      <v-btn text small @click="newRequest" v-if="!loading && !pending_request">
        {{ $t("requestInformalCare") }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
         <informal-care-request-modal ref="modal" v-if="!loading" :has_open_request="checkOpenRequest" @refresh="getCareRequests" @requestPending="pending_request = true">
    </informal-care-request-modal>
    </v-footer>


  </v-card>
</template>

<script>
import InformalCareRequestModal from "../InformalCareRequestModal.vue";
export default {
  components: {
    InformalCareRequestModal,
  },
  data() {
    return {
      pending_request: false,
      loading: false,
      selected_item: null,
      care_requests: [],
      headers: [
        {
          text: this.$t("createdAt"),
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("approvalStatus"),
          align: "start",
          sortable: true,
          value: "approval_status",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  created() {
    this.getCareRequests();
  },
  methods: {
    newRequest() {
      this.selected_item = null;
      this.$refs.modal.show = true;
    },
    onRowClick(item) {
      this.$refs.modal.setRequest(item);
    },
    getCareRequests() {
      this.loading = true;
      this.$http
        .get(this.$config.backend_url + "/informalcare-requests")
        .then((response) => {
          this.care_requests = response.data;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.$toast.error('Kon niet laden');
        });
    },
  },
  computed: {
    checkOpenRequest() {
      let has_open_request = false;

      this.care_requests.forEach((request) => {
        if (request.status == "FINISHED" && request.approval_status == "OPEN") {
          has_open_request = true;
          this.pending_request = true;
        }else{
          this.pending_request = false;
        }
      });

      return has_open_request;
    },
  },
};
</script>

<style>
.middle-aligned-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
}
</style>
