<template>
    <v-row class="justify-center">
        <v-col :cols="12" :sm="12" :md="10" :lg="10">
            <v-card color="white">
                <div class="form-title">{{ $t("requestInformalCareSupport") }}</div>

                <v-card-text>
                    <div v-if="request.status == 'FINISHED'" class="success-message">
                        <span class="py-5" v-if="request_submitted">
                            <v-icon color="green">mdi-check-circle-outline</v-icon>
                            <p>{{ $t("requestSubmitted") }}</p>
                        </span>
                    </div>

                    <div v-else-if="error_on_save && request.status == 'FINISHED'">
                        <v-alert v-if="account_activated" outlined transition type="success" prominent border="left"
                            color="success">
                            {{ $t("errorOnSave") }}
                        </v-alert>
                    </div>

                    <div v-else>
                        <v-stepper class="mt-5 no-shadow" flat v-model="step">
                            <v-stepper-header flat>
                                <v-stepper-step :complete="step > 1" step="1" @click.native="step = 1">{{
                                    $t("caregiverRecords")
                                    }}</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="step > 2" step="2" @click.native="step = 2">{{
                                    $t("informalCareSupport")
                                    }}</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step step="3" @click.native="step = 3">{{
                                    $t("declarationAndConsent")
                                    }}</v-stepper-step>
                            </v-stepper-header>

                            <v-stepper-items>
                                <!-- Step 1 Content -->
                                <v-stepper-content step="1">
                                    <div v-show="step === 1">
                                        <p class="explainer">{{ $t("caregiverIntro") }}</p>
                                        <v-form ref="caregiver_form">
                                            <v-text-field class="required" v-model="request.caregiver.first_name"
                                                :label="$t('firstName')" :rules="[required]"
                                                validate-on-blur></v-text-field>
                                            <v-text-field v-model="request.caregiver.preposition"
                                                :label="$t('preposition')"></v-text-field>
                                            <v-text-field class="required" v-model="request.caregiver.last_name"
                                                :label="$t('lastName')" :rules="[required]"
                                                validate-on-blur></v-text-field>

                                            <date-picker v-model="request.caregiver.date_of_birth"
                                                :label="$t('dateOfBirth')" />

                                            <v-text-field class="hide-arrows" v-model="request.caregiver.phonenumber"
                                                :label="$t('phoneNumber')" :rules="phoneNumberFormat"
                                                v-mask="'##########'" validate-on-blur type="number"></v-text-field>

                                            <v-text-field class="required" v-model="request.caregiver.email"
                                                :label="$t('email')" :rules="emailRules" validate-on-blur
                                                @blur="checkEmail"></v-text-field>

                                            <address-lookup :address="request.caregiver"
                                                @update:postalCode="updatePostalCode"></address-lookup>

                                            <v-checkbox v-model="hasPass" :label="$t('alreadyHavePass')"> </v-checkbox>

                                            <v-text-field counter class="numberinput" v-if="hasPass && !request.extension"
                                                v-model="request.caregiver.card_number" :rules="tokenRules"
                                                :label="$t('postToken')" type="number"
                                                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                maxlength="7" filled>
                                                <template v-slot:prepend>
                                                    <span style="white-space: nowrap">{{ preToken }}</span>
                                                </template>
                                            </v-text-field>

                                            <v-checkbox v-model="request.extension"
                                                :label="$t('careRequestExtension')"></v-checkbox>

                                            <v-text-field counter class="numberinput" v-if="request.extension"
                                                v-model="request.caregiver.card_number" :rules="tokenRules"
                                                :label="$t('postToken')" type="number"
                                                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                maxlength="7" filled>
                                                <template v-slot:prepend>
                                                    <span style="white-space: nowrap">{{ preToken }}</span>
                                                </template>
                                            </v-text-field>

                                            <v-alert v-if="hasPass && valid_card === false" outlined transition
                                                type="error" prominent border="left" color="error">
                                                {{ $t("invalidCard") }}
                                            </v-alert>

                                            <v-alert v-if="hasPass && valid_card === true" outlined transition
                                                type="success" prominent border="left" color="success">
                                                {{ $t("validCard") }}
                                            </v-alert>

                                            <v-alert v-if="request_exists" outlined transition type="error" prominent border="left" color="error">
                                                {{ $t("openRequestAlreadyExists") }}
                                            </v-alert>
                                        </v-form>

                                        <div class="stepper-navigation">
                                            <v-btn outlined rounded class="mt-5" color="primary" disabled>
                                                <v-icon left>mdi-chevron-left</v-icon>
                                                {{ $t("previous") }}</v-btn>
                                            <v-btn outlined rounded class="mt-5" color="primary" :disabled="(hasPass && (valid_card === false || valid_card === null)) ||
                                                request_exists
                                                " @click="validateStep(2, 'caregiver_form')">
                                                {{ $t("next") }}
                                                <v-icon right>mdi-chevron-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-stepper-content>

                                <!-- Step 2 Content -->
                                <v-stepper-content step="2">
                                    <div v-show="step === 2">
                                        <p class="explainer">{{ $t("informalCareIntro") }}</p>
                                        <p class="explainer">Let op: vermeld hier geen details van het ziektebeeld van de zorgvrager.</p>
                                        <v-form ref="carereceiver_form">
                                            <template v-if="$config.show_carereceiver_address">
                                                <v-text-field v-model="request.carereceiver.prefix"
                                                    :label="$t('prefix')"></v-text-field>
                                                <v-text-field class="required" v-model="request.carereceiver.first_name"
                                                    :label="$t('firstName')" :rules="[required]"
                                                    validate-on-blur></v-text-field>
                                                <v-text-field v-model="request.carereceiver.preposition"
                                                    :label="$t('preposition')"></v-text-field>
                                                <v-text-field class="required" v-model="request.carereceiver.last_name"
                                                    :label="$t('lastName')" :rules="[required]"
                                                    validate-on-blur></v-text-field>

                                                <date-picker v-model="request.carereceiver.date_of_birth"
                                                    :label="$t('dateOfBirth')" />

                                                <v-text-field v-model="request.carereceiver.email" :label="$t('email')"
                                                    :rules="emailFormat" validate-on-blur></v-text-field>
                                                <address-lookup :address="request.carereceiver"></address-lookup>
                                            </template>

                                            <v-text-field class="required" v-model="request.questions.hours_per_week"
                                                :label="$t('hoursCarePerWeek')" type="number" min="0"
                                                :rules="[required]" validate-on-blur v-mask="'###'"></v-text-field>

                                            <date-picker v-model="request.since_date" :label="$t('careSinceDate')" />

                                            <v-textarea class="required" v-model="request.questions.relationship"
                                                :label="$t('relationshipToCarereceiver')" :rules="[required]"
                                                validate-on-blur
                                                :hint="$t('relationshipToCarereceiverHint')"></v-textarea>

                                            <v-textarea class="required" v-model="request.questions.caregiver_duties"
                                                :label="$t('caregiverDuties')" :rules="[required]" validate-on-blur
                                                :hint="$t('caregiverDutiesHint')"></v-textarea>

                                        </v-form>

                                        <div class="stepper-navigation">
                                            <v-btn outlined rounded class="mt-5" color="primary" @click="step--">
                                                <v-icon left>mdi-chevron-left</v-icon>
                                                {{ $t("previous") }}</v-btn>
                                            <v-btn outlined rounded class="mt-5" color="primary"
                                                @click="validateStep(3, 'carereceiver_form')">{{
                                                $t("next") }}

                                                <v-icon right>mdi-chevron-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-stepper-content>

                                <!-- Step 3 Content -->
                                <v-stepper-content step="3">
                                    <div v-show="step === 3">
                                        <v-form ref="terms_form">
                                            <v-row>
                                                <v-col cols="auto" class="align-self-start">
                                                    <v-checkbox v-model="request.terms.agreed_conditions"
                                                        class="required" :rules="[required]"></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <div class="mt-5">{{ $t("caregiverRequestText") }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-text-field class="required" v-model="request.terms.place"
                                                :label="$t('signedAt')" :rules="[required]"
                                                validate-on-blur></v-text-field>
                                            <date-picker v-model="request.terms.date" :label="$t('signedOn')"
                                                :rules="[required]" validate-on-blur />
                                            <v-row>
                                                <v-col cols="auto" class="align-self-start">
                                                    <v-checkbox v-model="request.filled_in_by"> </v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <div class="mt-5">{{ $t("filledInByThirdLabel") }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="request.filled_in_by">
                                                <v-col>
                                                    {{ $t("filledInByThirdText") }}
                                                    <v-text-field class="required" v-model="request.filled_in_by_name"
                                                        :label="$t('name')" :rules="[required]"
                                                        validate-on-blur></v-text-field>
                                                    <v-text-field class="hide-arrows"
                                                        v-model="request.filled_in_by_phone_number"
                                                        :label="$t('phoneNumber')" :rules="phoneNumberFormat"
                                                        v-mask="'##########'" validate-on-blur
                                                        type="number"></v-text-field>

                                                    <v-text-field class="required" v-model="request.filled_in_by_email"
                                                        :label="$t('email')" :rules="emailRules"
                                                        validate-on-blur></v-text-field>
                                                    <v-text-field class="required"
                                                        v-model="request.filled_in_by_relation"
                                                        :label="$t('Relatie tot mantelzorger')"
                                                        validate-on-blur></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="hasTranslation('subscribeToCareNewsletter')">
                                                <v-col cols="auto">
                                                    <v-checkbox v-model="request.terms.newsletter"></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <div class="mt-5">{{ $t("subscribeToCareNewsletter") }}</div>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="$config.privacy_policy_url">
                                                <v-col cols="auto">
                                                    <v-checkbox v-model="request.terms.agreed_privacy_policy"
                                                        class="required" :rules="[required]">
                                                        <template v-slot:label>
                                                            <div class="ml-6">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on }">
                                                                        <a target="_blank"
                                                                            :href="$config.privacy_policy_url"
                                                                            @click.stop v-on="on">
                                                                            {{ $t("agreeWithPrivacyPolicy") }}
                                                                        </a>
                                                                    </template>
                                                                    {{ $t("urlNewWindow") }}
                                                                </v-tooltip>
                                                            </div>
                                                        </template>
                                                    </v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                        <div class="stepper-navigation">
                                            <v-btn outlined rounded class="mt-5" color="primary" @click="step--">
                                                <v-icon left>mdi-chevron-left</v-icon>
                                                {{ $t("previous") }}</v-btn>
                                            <v-btn rounded class="mt-5" color="primary"
                                                @click="submitRequest('FINISHED')">{{ $t("finishApplication") }}

                                                <v-icon right>mdi-check</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import AddressLookup from "../components/AddressLookup.vue";
export default {
    components: { AddressLookup },
    data() {
        return {
            loading: false,
            step: 1,
            error: false,
            required: (value) => !!value || this.$t("mandatory"),
            emailRules: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/.test(v) || this.$t("validEmail"),
            ],
            emailFormat: [(v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("validEmail")],
            postcalCode: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^(?:NL-)?(\d{4})\s*([A-Za-z]{2})$/.test(v) || this.$t("invalidPostalCode"),
            ],
            phoneNumberRules: [
                (v) => !!v || this.$t("mandatory"),
                (v) => v.length == 10 || this.$t("incorrectPhonenumber"),
            ],
            phoneNumberFormat: [(v) => !v || v.length == 10 || this.$t("incorrectPhonenumber")],
            tokenRules: [
                (value) => !!value || this.$t("mandatory"),
                (v) => (v || "").length <= 7 || this.$t("sevenCharacters"),
            ],
            request: {
                carereceiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                },
                caregiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                    card_number: "",
                    participant_id: null,
                },
                questions: {
                    relationship: "",
                    caregiver_duties: "",
                    carereceiver_clinical_picture: "",
                    hours_per_week: "",
                },
                terms: {
                    place: "",
                    date: new Date().toISOString().trim(0, 10),
                    agreed_privacy_policy: false,
                    agreed_conditions: false,
                    newsletter: false,
                },
                status: "DRAFT",
                accesstoken: this.accesstoken,
                since_date: "",
            },
            request_submitted: false,
            saved_as_draft: false,
            error_on_save: false,
            loading_address: false,
            carereceiver_form: true,
            caregiver_form: true,
            terms_form: true,
            hasPass: false,
            filled_in_by: false,
            signing: false,
            valid_card: null,
            faulty_instance: false,
            filled_in_by_email: "",
            filled_in_by_name: "",
            filled_in_by_phone_number: "",
            filled_in_by_relation: "",
            request_exists: false,
            extension: false,
        };
    },
    created() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.faulty_instance = false;
            await this.getTranslations();
            await this.getInstanceSettings();
        },
        validateStep(step, form) {
            this[form] = this.$refs[form].validate();
            if (this[form]) {
                this.step = step;
            }
        },
        validateForms() {
            this.carereceiver_form = this.$refs.carereceiver_form.validate();
            this.caregiver_form = this.$refs.caregiver_form.validate();
            this.terms_form = this.$refs.terms_form.validate();

            if (this.carereceiver_form && this.caregiver_form && this.terms_form) {
                return true;
            }

            return false;
        },
        updatePostalCode(newPostalCode) {
            this.request.caregiver.postal_code = newPostalCode;
            this.checkDataAndPost();
        },
        checkDataAndPost() {
            if (this.hasPass && this.request.caregiver.card_number && this.request.caregiver.postal_code) {
                const cardNumberValid = this.request.caregiver.card_number.length === 7;
                const postalCodeValid = /^[0-9]{4}[A-Za-z]{2}$/.test(this.request.caregiver.postal_code);

                if (cardNumberValid && postalCodeValid) {
                    this.checkValidCard(this.request.caregiver.card_number, this.request.caregiver.postal_code);
                } else {
                    this.valid_card = null; // Reset valid_card when the card number is not complete or postal code is invalid
                }
            }
        },
        checkValidCard(cardnumber, postalCode) {
            this.loading = true;

            cardnumber = this.preToken + cardnumber;

            this.$http
                .post(this.$config.backend_url + "/public-carerequest/check", {
                    accesstoken: cardnumber,
                    postalcode: postalCode,
                })
                .then((response) => {
                    this.loading = false;
                    // check the json response on the message
                    if (response.status === 200) {
                        this.valid_card = true;
                        this.request.caregiver.participant_id = response.data.participant.id;
                    } else {
                        this.valid_card = false;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.status === 404) {
                        this.valid_card = false;
                    } else {
                        console.error("Error:", error);
                    }
                });
        },
        checkEmail() {
            if (this.emailRules.every((rule) => rule(this.request.caregiver.email))) {
                this.checkEmailExists();
            }
        },
        checkEmailExists() {
            this.$http
                .post(this.$config.backend_url + "/public-carerequest/user-has-request", {
                    email: this.request.caregiver.email,
                })
                .then(() => {
                    this.request_exists = false;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 409) {
                        this.request_exists = true;
                    } else {
                        console.error(error);
                    }
                });
        },
        submitRequest(status) {
            if (status == "FINISHED" && !this.validateForms()) {
                return this.$toast.error(this.$t("notAllFieldsWereFilled"));
            }
            this.error = false;
            this.request.status = status;
            if (this.request.caregiver.card_number) {
                this.request.caregiver.card_number = this.preToken + this.request.caregiver.card_number;
                this.request.accesstoken = this.request.caregiver.card_number;
            }
            this.loading = true;

            this.$http
                .post(this.$config.backend_url + "/public-carerequest", this.request, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then(() => {
                    this.loading = false;
                    if (this.request.status == "DRAFT") {
                        this.$toast.success(this.$t("savedAsDraft"));
                    }
                    if (this.request.status == "FINISHED") {
                        this.request_submitted = true;

                        // redirect to the return url if it is set after 3 seconds
                        if (this.$config.return_url) {
                            setTimeout(() => {
                                window.location.href = this.$config.return_url;
                            }, 4000);
                        }
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.error_on_save = true;
                });
        },
        async getInstanceSettings() {
            if (this.faulty_instance == true) {
                return;
            }

            await this.$http
                .get(process.env.VUE_APP_BACKEND_URL + "/instance-settings", {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then(async (response) => {
                    this.$store.dispatch("setConfigVars", response.data);
                    this.$store.dispatch("reset");
                    if (this.$route.query.token) {
                        this.activateAccount();
                    }

                    this.initializing = false;
                })
                .catch(() => {
                    this.initializing = false;
                    this.faulty_instance = true;
                });

            return;
        },
        async getTranslations() {
            let url = process.env.VUE_APP_BACKEND_URL + "/translations";
            await this.$http
                .get(url, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.$store.dispatch("setTranslations", response.data);
                        this.$i18n.setLocaleMessage("nl", response.data.nl);
                        this.$i18n.setLocaleMessage("en", response.data.en);
                    }
                })
                .catch(() => {
                    this.initializing = false;
                    this.faulty_instance = true;
                });

            return;
        },
        hasTranslation(key) {
            return this.$t(key) !== key;
        },
    },
    computed: {
        preToken() {
            return this.$config.pre_token
                .replace(/[^\dA-Z]/g, "")
                .replace(/(.{4})/g, "$1 ")
                .trim();
        },
        passFilledIn() {
            return this.request.caregiver.card_number.length === 7;
        },
    },
    watch: {
        "request.caregiver.card_number": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.checkDataAndPost();
            }
        },
        hasPass: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (!newVal) {
                    this.request.caregiver.card_number = null;
                }
                this.checkDataAndPost();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.success-message {
    text-align: center;

    span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        font-size: 1.5rem;
        margin-left: 50px;
        margin-right: 50px;
        font-weight: 100;

        i {
            font-size: 100px;
            margin-bottom: 30px;
        }
    }
}

.stepper-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

// on small screens stepper navigation content should be stacked
@media (max-width: 600px) {
    .stepper-navigation {
        flex-direction: column;
    }
}

/* Chrome, Safari, Edge, Opera */
.numberinput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.numberinput input[type="number"] {
    -moz-appearance: textfield;
}

.min-height-container {
    min-height: 800px;
}
</style>
